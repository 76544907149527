@media screen and (min-width: 1200px) {
  .browse {
      main {
          .biblioteca {
              .nuevos.destacados {
                  margin-top: 150px;
              }
              .nuevos.nuevos-en-vivo {
                  margin: 150px 0 -150px;
              }
          }
      }
  }
}
@media screen and (min-width: 2500px) {
  .browse {
      main {
          .biblioteca {
              .nuevos.destacados {
                  margin-top: 100px;
              }
          }
      }
  }
}
@media screen and (min-width: 3000px) {
  .browse {
      main {
          .biblioteca {
              .nuevos.destacados {
                  margin-top: 150px;
              }
          }
      }
  }
}