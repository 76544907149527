.destacadoInfo {
    width: 96%;
    height: 100%;
    display: flex;
    padding: 0 0 0 4%;
    position: absolute;
    top: 0;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(
        90deg,
        rgba(5, 17, 28, 0.75) 12.57%,
        rgba(67, 98, 126, 0) 95%
    );
    h2 {
        width: 35%;
        margin: 0 0 15px 0;
        font-size: 3.5vw;
        font-weight: 500;
    }
    .description {
        display: none;
    }
    .duration {
        display: block;
        margin: 10px 0;
        font-size: 1.5rem;
    }
    .primary-button,
    .secondary-button {
        // max-width: 300px;
        margin: 0 auto 0 0;
        padding: 3px 5px;
        border-radius: 2px;
        text-decoration: none;
    }
    .primary-button {
        background-color: var(--auf);
        border: 1px solid var(--auf);
        text-transform: uppercase;
        .playVideoIcon {
            margin: 0 0 0 5px;
        }
    }
    .secondary-button {
        border: 1px solid var(--color-claro);
    }
}

@media screen and (min-width: 481px) {
    .destacadoInfo {
        a.primary-button {
            font-size: 1.5rem;
            margin: 0 auto 0 0;
            padding: 5px 30px;
            border-radius: 5px;
        }
    }
}

@media screen and (min-width: 481px) {
    .destacadoInfo {
        h2 {
            font-size: 2rem;
        }
    }
}

@media screen and (min-width: 769px) {
    .destacadoInfo {
        h2 {
            margin: -10vw 0 15px;
            font-size: 2.5rem;
        }
    }
}

@media screen and (min-width: 1201px) {
    .destacadoInfo {
        h2 {
            font-size: 3rem;
        }
    }
}
