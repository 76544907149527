.modalVideoCard {
    width: 100vw;
    max-width: 500px;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    background-color: var(--background);
    z-index: 3;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    .closeVideoModalIcon {
        padding: 4px 7px;
        position: absolute;
        right: 15px;
        top: 15px;
        background-color: var(--background);
        color: blue;
        font-size: 20px;
        border-radius: 50%;
        z-index: 1;
    }
    .videoPoster {
        position: relative;
        img {
            width: 100%;
            position: relative;
            margin: 0 auto;
        }
    }
    a {
        width: 90%;
        margin: 5px auto;
        padding: 5px;
        text-align: center;
        background-color: var(--pri);
        font-size: 18px;
        border: none;
        border-radius: 2px;
        text-decoration: none;
        .modalPlayVideoIcon {
            padding-left: 5px;
            font-size: 15px;
        }
    }
    .title,
    .description {
        padding: 0 3vw;
    }
    .title {
        margin: 25px 3vw 0;
        h1 {
            font-size: 21px;
        }
        p {
            font-size: 15px;
            margin-top: -25px;
        }
    }
    .description {
        margin: 9px 3vw 0;
        p {
            font-size: 15px;
        }
    }
    .modalCategories {
        margin: 15px 3vw;
        padding: 0 3vw;
        font-size: 1.5rem;
        h3 {
            padding: 0 0 5px;
            color: var(--pri);
            border-bottom: 1px solid;
        }
        a.modalCategories-link {
            margin: 15px 10px 0 0;
            padding: 5px 7px;
            background: none;
            display: inline-block;
            text-align: start;
            border: 1px solid;
            width: auto;
            font-size: 1.5rem;
        }
    }
    .modalTags {
        padding: 0 3vw 50px;
        font-size: 1.5rem;
        h3 {
            margin: 0 0 0 3vw;
            padding: 0 0 5px;
            color: var(--pri);
            border-bottom: 1px solid;
        }
        .modalTags-link {
            margin: 10px 0 0 0;
            padding: 5px 3vw;
            display: flex;
            align-items: center;
            img {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
            p {
                margin: 0 0 0 10px;
            }
        }
        a.modalTags-link {
            background: none;
        }
    }
    .space {
        margin: 0 0 150px;
    }
}
