.terms {
  background: var(--dark-color);
  min-height: 90vh;

  .inner {
      max-width: 1200px;
      margin: 0 auto;
      padding: 90px 20px;
      h1 {
          font-size: 18px;
          margin-top: 25px;
      }
      h2 {
          font-size: 22px;
          margin-bottom: 10px;
      }
      .contact-h2 {
          margin: 0 15px 10px 0;  
      }
      h3 {
          font-size: 18px;
          margin: 25px 0 10px; 
          display: flex;
          align-items: center; 
          img {
              height: 40px; 
              margin-right: 10px;
          }
      }
      h5 {
        font-size: 15px;
        margin: 25px 0 10px; 
        display: flex;
        align-items: center; 
        img {
            height: 40px; 
            margin-right: 10px;
        }
      }
      p {
          color: var(--neu-pri);
          margin-bottom: 30px;
      }
      .paragrafo_contacto {
          font-size: 18px; 
          a {
              color: var(--lpf-cel); 
              text-decoration: none; 
              &:hover {
                  text-decoration: underline; 
              }
          }
      }
  }
}

@media screen and (min-width: 481px) {
  // Magia de pepe aca
}

@media screen and (min-width: 769px) {
  // Magia de pepe aca
}
