.proximamente {
  height: 120vh; 
  background-color: var(--lpf-azu); 
  .header {
    display:none; 
  }
  .secondary-button {
    display: none;
  }
  .proximamente-hero { 
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    padding: 30vh;
    img {
      width: 50vw; 
    }
    h1 {
      text-align: center;
      font-size: 3rem;
    }
  }
  .footer {
    display: none;
  }
}