.pack-page {
  min-height: 90vh; 
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  .pack-container { 
    width: 100vw; 
    margin: 15vh auto 10px;
    display: flex; 
    justify-content: center; 
    flex-wrap: wrap;
    .pack {
      width: 330px; 
      margin: 10px 10px 35px; 
      padding: 0 20px; 
      background: rgb(0,71,107);
      background: linear-gradient(0deg, rgba(0,71,107,1) 0%, rgba(0,71,107,1) 79%, rgba(46,92,115,1) 100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid var(--lpf-oro); 
      box-shadow: 2px 2px 2px var(--lpf-oro);
      h2 {
        width: 90%;
        margin-top: -15px;
        background-color: var(--lpf-oro);
        color: var(--lpf-azu);
        color: var(--color-claro);
        text-align: center;
        font-size: 25px;
        line-height: 1.8;
        border: 1px solid var(--lpf-oro);
        border-radius: 3px;
        box-shadow: 2px 2px 2px #515151;
      } 
      h3 {
        line-height: 57px;
        font-size: 30px;
      }
      p { 
        height: 25px;
        font-size: 15px; 
        text-align: center; 
        color: var(--color-claro); 
      } 
      .nota {
        font-size: 12px; 
        color: #b7b7b7;
      }
      .pack-features { 
        width: 90%; 
        height: 100px;
        margin: 20px 0 35px;
        text-align: center;
        padding-inline-start: 0px;
        li { 
          padding: 0 0 0 10px; 
          font-size: 15px;
          color: var(--color-claro); 
          list-style: none;  
          text-align: start;
          &::marker {
            content: url('/assets/logo_lpf-marker.png'); 
          }
        } 
        .boldli {
          font-weight: bold;
        }
      }
      .pack-button { 
        margin: 35px 0 20px; 
        padding: 3px 15px;
        font-size: 20px; 
        background-color: var(--lpf-cel);
        border-radius: 3px;
        border: none; 
        box-shadow: 3px 3px 3px #272727; 
        &:hover {
          background-color: #0692ab;
        } 
        a {
          text-decoration: none;
        }
      } 
      transition: all .7s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
} 